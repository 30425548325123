import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FiArrowLeft } from 'react-icons/fi';
import { Props, Playground } from 'docz';
import { Flex } from '../Flex';
import { Box } from '../Box';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import ButtonNew from './ButtonNew';
import ButtonSelect from './ButtonSelect';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <p>{`Buttons are components which signify that an action will occur when a user interacts with them.`}</p>
    <Props of={Button} mdxType="Props" />
    <h2 {...{
      "id": "standard-button"
    }}>{`Standard Button`}</h2>
    <p>{`This is your standard button. They typically trigger one action when interacted with, providing the basis for creating an interactive page. Add additional context to them using the context prop.`}</p>
    <Playground __position={1} __code={'<ButtonGroup>\n  <Button>Click me</Button>\n  <Button context=\"shadower\">Shadower</Button>\n  <Button context=\"host\">Host</Button>\n  <Button context=\"danger\">Danger</Button>\n</ButtonGroup>\n<Box bg=\"primary.base\" padding=\"large\" marginTop=\"large\">\n  <Button context=\"whiteout\">Whiteout</Button>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <Button mdxType="Button">Click me</Button>
    <Button context="shadower" mdxType="Button">Shadower</Button>
    <Button context="host" mdxType="Button">Host</Button>
    <Button context="danger" mdxType="Button">Danger</Button>
  </ButtonGroup>
  <Box bg="primary.base" padding="large" marginTop="large" mdxType="Box">
    <Button context="whiteout" mdxType="Button">Whiteout</Button>
  </Box>
    </Playground>
    <h2 {...{
      "id": "outline-button"
    }}>{`Outline Button`}</h2>
    <p>{`These are our outline buttons. Use them for secondary actions. Most of the time, this will be when used alongside a standard button or primary action.`}</p>
    <Playground __position={2} __code={'<ButtonGroup>\n  <Button variant=\"outline\">Click me</Button>\n  <Button variant=\"outline\" context=\"shadower\">\n    Shadower\n  </Button>\n  <Button variant=\"outline\" context=\"host\">\n    Host\n  </Button>\n  <Button variant=\"outline\" context=\"danger\">\n    Danger\n  </Button>\n</ButtonGroup>\n<Box bg=\"primary.base\" padding=\"large\" marginTop=\"large\">\n  <Button variant=\"outline\" context=\"whiteout\">\n    Whiteout\n  </Button>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <Button variant='outline' mdxType="Button">Click me</Button>
    <Button variant='outline' context="shadower" mdxType="Button">Shadower</Button>
    <Button variant='outline' context="host" mdxType="Button">Host</Button>
    <Button variant='outline' context="danger" mdxType="Button">Danger</Button>
  </ButtonGroup>
  <Box bg="primary.base" padding="large" marginTop="large" mdxType="Box">
    <Button variant="outline" context="whiteout" mdxType="Button">Whiteout</Button>
  </Box>
    </Playground>
    <h2 {...{
      "id": "subtle-button"
    }}>{`Subtle Button`}</h2>
    <p>{`These are our subtle buttons. Use them for when the action is not overly important and should blend into its surroundings.`}</p>
    <Playground __position={3} __code={'<ButtonGroup>\n  <Button variant=\"subtle\">Click me</Button>\n  <Button variant=\"subtle\" context=\"shadower\">\n    Shadower\n  </Button>\n  <Button variant=\"subtle\" context=\"host\">\n    Host\n  </Button>\n  <Button variant=\"subtle\" context=\"danger\">\n    Danger\n  </Button>\n</ButtonGroup>\n<Box bg=\"primary.base\" padding=\"large\" marginTop=\"large\">\n  <Button variant=\"subtle\" context=\"whiteout\">\n    Whiteout\n  </Button>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      <ButtonGroup mdxType="ButtonGroup">
        <Button variant='subtle' mdxType="Button">Click me</Button>
        <Button variant='subtle' context="shadower" mdxType="Button">Shadower</Button>
        <Button variant='subtle' context="host" mdxType="Button">Host</Button>
        <Button variant='subtle' context="danger" mdxType="Button">Danger</Button>
      </ButtonGroup>
      <Box bg="primary.base" padding="large" marginTop="large" mdxType="Box">
  <Button variant="subtle" context="whiteout" mdxType="Button">Whiteout</Button>
      </Box>
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <p>{`All variants can come in three sizes.`}</p>
    <Playground __position={4} __code={'<ButtonGroup alignItems=\"center\">\n  <Button display=\"inline-block\">Default size</Button>\n  <Button display=\"inline-block\" size=\"small\">\n    Small\n  </Button>\n  <Button display=\"inline-block\" size=\"base\">\n    Base is default\n  </Button>\n  <Button display=\"inline-block\" size=\"large\">\n    Large\n  </Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup alignItems="center" mdxType="ButtonGroup">
    <Button display="inline-block" mdxType="Button">Default size</Button>
    <Button display="inline-block" size="small" mdxType="Button">
      Small
    </Button>
    <Button display="inline-block" size="base" mdxType="Button">
      Base is default
    </Button>
    <Button display="inline-block" size="large" mdxType="Button">
      Large
    </Button>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "icon-buttons"
    }}>{`Icon Buttons`}</h2>
    <p>{`Buttons that contain `}<em parentName="p">{`just`}</em>{` an icon automatically adjust padding to become round.`}</p>
    <Playground __position={5} __code={'<ButtonGroup alignItems=\"center\">\n  <Button icon={true} display=\"inline-block\" size=\"small\">\n    <FiArrowLeft style={{ marginBottom: \'-0.125em\' }} />\n  </Button>\n  <Button icon={true} display=\"inline-block\">\n    <FiArrowLeft style={{ marginBottom: \'-0.125em\' }} />\n  </Button>\n  <Button icon={true} display=\"inline-block\" size=\"large\">\n    <FiArrowLeft style={{ marginBottom: \'-0.125em\' }} />\n  </Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup alignItems="center" mdxType="ButtonGroup">
    <Button icon={true} display="inline-block" size="small" mdxType="Button">
      <FiArrowLeft style={{
            marginBottom: '-0.125em'
          }} mdxType="FiArrowLeft" />
    </Button>
    <Button icon={true} display="inline-block" mdxType="Button">
      <FiArrowLeft style={{
            marginBottom: '-0.125em'
          }} mdxType="FiArrowLeft" />
    </Button>
    <Button icon={true} display="inline-block" size="large" mdxType="Button">
      <FiArrowLeft style={{
            marginBottom: '-0.125em'
          }} mdxType="FiArrowLeft" />
    </Button>
  </ButtonGroup>
    </Playground>
    <Playground __position={6} __code={'<ButtonGroup alignItems=\"center\">\n  <Button display=\"inline-block\" size=\"small\">\n    <FiArrowLeft style={{ marginBottom: \'-0.125em\' }} /> Back\n  </Button>\n  <Button display=\"inline-block\">\n    <FiArrowLeft style={{ marginBottom: \'-0.125em\' }} /> Back\n  </Button>\n  <Button display=\"inline-block\" size=\"large\">\n    <FiArrowLeft style={{ marginBottom: \'-0.125em\' }} /> Back\n  </Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup alignItems="center" mdxType="ButtonGroup">
    <Button display="inline-block" size="small" mdxType="Button">
      <FiArrowLeft style={{
            marginBottom: '-0.125em'
          }} mdxType="FiArrowLeft" /> Back
    </Button>
    <Button display="inline-block" mdxType="Button">
      <FiArrowLeft style={{
            marginBottom: '-0.125em'
          }} mdxType="FiArrowLeft" /> Back
    </Button>
    <Button display="inline-block" size="large" mdxType="Button">
      <FiArrowLeft style={{
            marginBottom: '-0.125em'
          }} mdxType="FiArrowLeft" /> Back
    </Button>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "groups-of-buttons"
    }}>{`Groups of Buttons`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`<ButtonGroup>`}</inlineCode>{` to automatically space out Buttons.`}</p>
    <p><inlineCode parentName="p">{`ButtonGroup`}</inlineCode>{` is based on `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` and takes all flex props. It defaults to `}<inlineCode parentName="p">{`flex-direction: row`}</inlineCode>{` and will automatically apply a `}<inlineCode parentName="p">{`small`}</inlineCode>{` space between buttons.`}</p>
    <Playground __position={7} __code={'<ButtonGroup>\n  <Button>Click me</Button>\n  <Button>Click me</Button>\n  <Button>Click me</Button>\n  <Button>Click me</Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <Button mdxType="Button">Click me</Button>
    <Button mdxType="Button">Click me</Button>
    <Button mdxType="Button">Click me</Button>
    <Button mdxType="Button">Click me</Button>
  </ButtonGroup>
    </Playground>
    <p>{`Change the layout to be vertical, by applying `}<inlineCode parentName="p">{`flexDirection='column'`}</inlineCode></p>
    <Playground __position={8} __code={'<ButtonGroup flexDirection=\"column\">\n  <Button>Click me</Button>\n  <Button>Click me</Button>\n  <Button>Click me</Button>\n  <Button>Click me</Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup flexDirection="column" mdxType="ButtonGroup">
    <Button mdxType="Button">Click me</Button>
    <Button mdxType="Button">Click me</Button>
    <Button mdxType="Button">Click me</Button>
    <Button mdxType="Button">Click me</Button>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "new-button"
    }}>{`New Button`}</h2>
    <Props of={ButtonNew} mdxType="Props" />
    <p>{`This is your standard button. They typically trigger one action when interacted with, providing the basis for creating an interactive page. Add additional context to them using the context prop.`}</p>
    <Playground __position={10} __code={'<Box padding=\"base\">\n  <ButtonGroup>\n    <ButtonNew context=\"neutral\">Neutral Full</ButtonNew>\n    <ButtonNew context=\"shadower\" marginLeft=\"small\">\n      Shadower\n    </ButtonNew>\n    <ButtonNew context=\"host\" marginLeft=\"small\">\n      Host\n    </ButtonNew>\n    <ButtonNew context=\"danger\" marginLeft=\"small\">\n      Danger\n    </ButtonNew>\n  </ButtonGroup>\n  <ButtonGroup marginY=\"base\">\n    <ButtonNew variant=\"outline\" context=\"neutral\">\n      Neutral Outline\n    </ButtonNew>\n    <ButtonNew variant=\"outline\" context=\"shadower\" marginLeft=\"small\">\n      Shadower\n    </ButtonNew>\n    <ButtonNew variant=\"outline\" context=\"host\" marginLeft=\"small\">\n      Host\n    </ButtonNew>\n    <ButtonNew variant=\"outline\" context=\"danger\" marginLeft=\"small\">\n      Danger\n    </ButtonNew>\n  </ButtonGroup>\n  <ButtonGroup marginY=\"base\">\n    <ButtonNew variant=\"subtle\" context=\"neutral\">\n      Neutral Subtle\n    </ButtonNew>\n    <ButtonNew variant=\"subtle\" context=\"shadower\" marginLeft=\"small\">\n      Shadower\n    </ButtonNew>\n    <ButtonNew variant=\"subtle\" context=\"host\" marginLeft=\"small\">\n      Host\n    </ButtonNew>\n    <ButtonNew variant=\"subtle\" context=\"danger\" marginLeft=\"small\">\n      Danger\n    </ButtonNew>\n  </ButtonGroup>\n</Box>\n<Box bg=\"neutral.darker\" padding=\"large\">\n  <ButtonNew context=\"whiteout\">Whiteout</ButtonNew>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box padding="base" mdxType="Box">
    <ButtonGroup mdxType="ButtonGroup">
      <ButtonNew context="neutral" mdxType="ButtonNew">Neutral Full</ButtonNew>
      <ButtonNew context="shadower" marginLeft="small" mdxType="ButtonNew">Shadower</ButtonNew>
      <ButtonNew context="host" marginLeft="small" mdxType="ButtonNew">Host</ButtonNew>
      <ButtonNew context="danger" marginLeft="small" mdxType="ButtonNew">Danger</ButtonNew>
    </ButtonGroup>
    <ButtonGroup marginY="base" mdxType="ButtonGroup">
      <ButtonNew variant="outline" context="neutral" mdxType="ButtonNew">Neutral Outline</ButtonNew>
      <ButtonNew variant="outline" context="shadower" marginLeft="small" mdxType="ButtonNew">Shadower</ButtonNew>
      <ButtonNew variant="outline" context="host" marginLeft="small" mdxType="ButtonNew">Host</ButtonNew>
      <ButtonNew variant="outline" context="danger" marginLeft="small" mdxType="ButtonNew">Danger</ButtonNew>
    </ButtonGroup>
    <ButtonGroup marginY="base" mdxType="ButtonGroup">
      <ButtonNew variant="subtle" context="neutral" mdxType="ButtonNew">Neutral Subtle</ButtonNew>
      <ButtonNew variant="subtle" context="shadower" marginLeft="small" mdxType="ButtonNew">Shadower</ButtonNew>
      <ButtonNew variant="subtle" context="host" marginLeft="small" mdxType="ButtonNew">Host</ButtonNew>
      <ButtonNew variant="subtle" context="danger" marginLeft="small" mdxType="ButtonNew">Danger</ButtonNew>
    </ButtonGroup>
  </Box>
  <Box bg="neutral.darker" padding="large" mdxType="Box">
    <ButtonNew context="whiteout" mdxType="ButtonNew">Whiteout</ButtonNew>
  </Box>
    </Playground>
    <h2 {...{
      "id": "select-button"
    }}>{`Select Button`}</h2>
    <Props of={ButtonSelect} mdxType="Props" />
    <p>{`Form single and multi select forms.`}</p>
    <Playground __position={12} __code={'<Box padding=\"base\" bg=\"whiteout.base\">\n  <ButtonGroup>\n    <ButtonSelect selected={false}>Option 1</ButtonSelect>\n    <ButtonSelect selected>Option 2 (Selected)</ButtonSelect>\n  </ButtonGroup>\n</Box>\n<Box bg=\"neutral.darker\" padding=\"large\">\n  <ButtonSelect context=\"whiteout\">Whiteout</ButtonSelect>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      FiArrowLeft,
      Props,
      Playground,
      Flex,
      Box,
      Button,
      ButtonGroup,
      ButtonNew,
      ButtonSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box padding="base" bg="whiteout.base" mdxType="Box">
    <ButtonGroup mdxType="ButtonGroup">
      <ButtonSelect selected={false} mdxType="ButtonSelect">Option 1</ButtonSelect>
      <ButtonSelect selected mdxType="ButtonSelect">Option 2 (Selected)</ButtonSelect>
    </ButtonGroup>
  </Box>
  <Box bg="neutral.darker" padding="large" mdxType="Box">
    <ButtonSelect context="whiteout" mdxType="ButtonSelect">Whiteout</ButtonSelect>
  </Box>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      